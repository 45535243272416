import * as React from "react";
import { Filter, FunctionField, TextInput } from 'react-admin';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { FilterEdit } from "./filter.edit.component";

const FilterListFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

const renameChoices = {
    'path': 'Cross-country skiing',
    'subPath': 'Snowmobile',
};

export const FilterList = () => {
    return (
        <List
            filters={<FilterListFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={<FilterEdit/>}>
                <TextField source="name" />
                <FunctionField label="Group Type" source="filterType" render={(record) => `${renameChoices[record.filterType]}`} />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}