import { makeStyles } from '@mui/styles';
import * as React from "react";
import {
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";

export const TermsCreate = () => {
  const statusOptions = Object.keys(PublishingStatus).map((key) => ({
    id: PublishingStatus[key],
    name: key,
  }));

  return (
    <Create>
      <SimpleForm>
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput
          source="terms"
          rows={8}
          multiline={true}
          fullWidth={true}
          validate={[required()]}
        />

        <TranslationInputs>
          <TextInput
            source="title_en"
            label="Title eng"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source="terms_en"
            label="Terms eng"
            rows={8}
            multiline={true}
            fullWidth={true}
            validate={[required()]}
          />
        </TranslationInputs>
      </SimpleForm>
    </Create>
  );
};
