import * as React from "react";
import { Filter, TextInput } from "react-admin";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { TimePeriodEdit } from "./timePeriod.edit.component";

const TimePeriodListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const TimePeriodList = () => {
  return (
    <List filters={<TimePeriodListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={<TimePeriodEdit/>}>
        <TextField source="name" />
        <DateField
          source="start"
          options={{ day: "2-digit", month: "short" }}
        />
        <DateField source="end" options={{ day: "2-digit", month: "short" }} />
      </Datagrid>
    </List>
  );
};
