import * as React from "react";
import {
  Edit,
  TextInput,
  DateInput,
  required,
  BooleanInput,
  SimpleForm,
  ImageInput,
  ImageField,
  useRecordContext,
} from "react-admin";
import CustomToolbar from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";

export const AreaOfInterestCategoryEdit = () => {
  const record = useRecordContext();

  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar noDelete={true} />}
      >
        <BooleanInput source="isListed" validate={[required()]} fullWidth />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="pageTitle" validate={[required()]} fullWidth />
        <TextInput source="description" rows={8} multiline fullWidth />
        <ImageInput source="media" multiple={false}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TranslationInputs>
          <TextInput source="name_en" label="Name eng" fullWidth />
          <TextInput source="pageTitle_en" label="Page Title eng" fullWidth />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline
            fullWidth
          />
        </TranslationInputs>
        <DateInput disabled source="createdAt" fullWidth />
        <DateInput disabled source="updatedAt" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
