import { makeStyles } from '@mui/styles';
import * as React from "react";
import { Edit, SimpleForm, TextInput, DateInput, required, useRecordContext } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const TagEdit = () => {
    const classes = useStyles();

    const record = useRecordContext();

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source="name" validate={[required()]} fullWidth/>
                <TranslationInputs>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                </TranslationInputs>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}