import * as React from "react";
import { makeStyles } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { BulkDeleteButton, BulkDeleteWithConfirmButton, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { AreaEdit } from "./area.edit.component";

const AreaListFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const AreaList = () => {
    return (
        <List
            filters={<AreaListFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={<AreaEdit/>}>
                <TextField source="name" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}