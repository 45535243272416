import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useEffect, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    DateInput,
    SelectInput,
    ReferenceInput,
    SelectArrayInput,
    ReferenceArrayInput,
    FileField,
    FileInput,
    required,
    number,
    DateTimeInput,
    useGetOne,
    useListContext,
    useRecordContext,
    useGetList,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { TimePeriod } from '../../shared/interfaces/timePeriod.interface';
import GetTimePeriodRange from '../../shared/services/timePeriod.services';
import { validateTimePeriod } from '../../shared/services/validateTimePeriod.service';
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex',
    marginRight: '1rem' },
});

export const PathEdit = () => {
    const classes = useStyles();
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "ASC" },
        },
    );
    const record = useRecordContext();
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const { id } = useParams();
    const path = useGetOne("paths", { id: id ?? record?.id });
    const selectedTimePeriod = GetTimePeriodRange(
        selectedTimePeriodId,
        timePeriods.data as any
    );
    const displayOptions = Object.keys(DisplayStatus).map(key => ({
        id: DisplayStatus[key],
        name: key,
    }));

    useEffect(() => {
      if (!path.isLoading) {
        setSelectedTimePeriodId(path?.data?.timePeriodId);
        setDisplayValue(path?.data?.display);
      }
    }, [path.isLoading]);

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="number" validate={[required()]}/>
                <TextInput source="length" label="Length (km)" validate={[required(), number()]}/>
                <TextInput source="start" label="start name" />
                <TextInput source="end" label="end name" />
                <DateTimeInput source="groomedAt" />
                <TextInput source="lastGPXFilename" label="Current GPX file" disabled />
                <SelectInput source="difficulty" validate={[required()]} defaultValue={'medium'} choices={[
                    { id: 'veryEasy', name: 'very easy' },
                    { id: 'easy', name: 'easy' },
                    { id: 'medium', name: 'medium' },
                    { id: 'hard', name: 'hard' },
                ]} />
                <SelectInput source="status" validate={[required()]} defaultValue={'open'} choices={[
                    { id: 'open', name: 'open' },
                    { id: 'closed', name: 'closed' },
                ]} />
                <ReferenceInput source="pathCategoryId" reference="pathcategories">
                    <SelectInput optionText="name" label="Category" validate={[required()]}/>
                </ReferenceInput>
                <ReferenceArrayInput source="area_ids" reference="areas">
                    <SelectArrayInput optionText="name" label="Areas" />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="poi_ids" reference="pois">
                    <SelectArrayInput label="Related Pois" optionText={record => {
                        if (record.areas.length > 0) {
                            return `${record.name} (${record.areas.map(area => area.name).join(', ')})`;
                        }
                        return record.name;
                    }}/>
                </ReferenceArrayInput>
                <ReferenceArrayInput source="tag_ids" reference="tags">
                    <SelectArrayInput optionText="name" label="Tags" />
                </ReferenceArrayInput>
                <FileInput source="gpx" label="GPX file" accept=".gpx">
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                    validate={[required()]}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        source="timePeriodId"
                        reference="timeperiods"
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            label="Time period"
                            helperText={selectedTimePeriod}
                            validate={[validateTimePeriod, required()]}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TranslationInputs>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                    <TextInput source="start_en" label="Start eng" fullWidth />
                    <TextInput source="end_en" label="End eng" fullWidth />
                    <TextInput source="description_en" label="Description eng" rows={8} multiline fullWidth />
                </TranslationInputs>
                <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}