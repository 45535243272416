import { Create, NumberInput, required, SimpleForm, TextInput } from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const StartPageRowCreate = () => {
    const classes = useStyles();

    return (
        <Create resource="startpages/rows" redirect="/startpages/1/rows">
            <SimpleForm>
                <TextInput source="title" validate={[required()]} />
                <TextInput source="title_en" />
                <NumberInput source="priority"/>

                <TextInput disabled source="pageId" defaultValue={1} style={{display:'none'}}/>
            </SimpleForm>
        </Create>
    );
};