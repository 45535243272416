import { Create, ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { EntryType } from "../../../../shared/enums/start-page.enum";

const useStyles = makeStyles({
    inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const getSourceForType = (type: string) => {
    switch (type) {
        case EntryType.Path:
            return 'paths';

        case EntryType.Poi:
            return 'pois';

        case EntryType.PathCategory:
            return 'pathCategories';

        case EntryType.PoiCategory:
            return 'poiCategories';

        default:
            return '';
    }
};

export const StartPageRowEntryCreate = () => {
    const classes = useStyles();
    const [referenceSource, setReferenceSource] = useState('');

    const { rowId } = useParams();

    return (
        <Create resource="startpages/entries" redirect={`/startpages/rows/${rowId}/entries`}>
            <SimpleForm>
                <SelectInput source="entryType" validate={[required()]} onChange={e => setReferenceSource(getSourceForType(e.target.value))} choices={[
                    { name: 'Path', id: EntryType.Path },
                    { name: 'Poi', id: EntryType.Poi },
                    { name: 'Path Category', id: EntryType.PathCategory },
                    { name: 'Poi Category', id: EntryType.PoiCategory },
                ]}/>
                { referenceSource && (
                    <>
                        <ReferenceInput source="entryId" reference={referenceSource}>
                            <SelectInput optionText="name" label="Category" validate={[required()]} />
                        </ReferenceInput>
                        <NumberInput source="priority"/>
                    </>
                )}

                <ImageInput source="media" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" />
                </ImageInput>

                <TextInput disabled source="rowId" defaultValue={rowId} style={{display:'none'}}/>
            </SimpleForm>
        </Create>
    );
};