import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  SelectInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  useGetOne,
  ReferenceArrayInput,
  SelectArrayInput,
  useRecordContext,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { MapConnection } from "./enums/map-connection.enum";
import { Recurrence } from "./enums/recurrence.enum";
import { Severity } from "./enums/severity.enum";
import { validateInformation } from "./services/validate-information.service";
import { startCase } from "lodash";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const InformationEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const record = useRecordContext();
  const information = useGetOne("information", { id: id ?? record?.id });
  const [mapConnectionValue, setMapConnectionValue] = useState(
    MapConnection.None
  );

  const mapConnectionOptions = Object.keys(MapConnection).map((key) => ({
    id: MapConnection[key],
    name: startCase(key),
  }));

  const statusOptions = Object.keys(PublishingStatus).map((key) => ({
    id: PublishingStatus[key],
    name: startCase(key),
  }));

  const severityOptions = Object.keys(Severity).map((key) => ({
    id: Severity[key],
    name: startCase(key),
  }));

  const recurrenceOptions = Object.keys(Recurrence).map((key) => ({
    id: Recurrence[key],
    name: startCase(key),
  }));

  useEffect(() => {
    if (!information.isLoading) {
      setMapConnectionValue(information?.data?.mapConnection);
    }
  }, [information.isLoading]);

  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        validate={validateInformation}
      >
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <SelectInput
          source="severity"
          validate={[required()]}
          choices={severityOptions}
        />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
          validate={[required()]}
        />
        <DateTimeInput source="startAt" defaultValue={new Date()} />
        <DateTimeInput source="endAt" />
        <TextInput source="buttonText" />
        <TextInput source="buttonUrl" />

        <ReferenceArrayInput source="tag_ids" reference="informationtags">
          <SelectArrayInput optionText="name" label="Tags" />
        </ReferenceArrayInput>

        <SelectInput
          source="mapConnection"
          choices={mapConnectionOptions}
          onChange={(e) =>
            setMapConnectionValue(e.target.value as MapConnection)
          }
          validate={[required()]}
        ></SelectInput>
        {mapConnectionValue === MapConnection.PointOfInterest && (
          <ReferenceInput
            source="pointOfInterestId"
            reference="pois"
          >
            <AutocompleteInput
              optionText="name"
              label="Point of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {mapConnectionValue === MapConnection.AreaOfInterest && (
          <ReferenceInput
            source="areaOfInterestId"
            reference="aois"
          >
            <AutocompleteInput
              optionText="name"
              label="Area of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {(mapConnectionValue === MapConnection.PointOfInterest ||
          mapConnectionValue === MapConnection.AreaOfInterest) && (
          <NumberInput source="radius" label="radius (m)" />
        )}
        <SelectInput
          source="recurrence"
          validate={[required()]}
          choices={recurrenceOptions}
        />
        <TranslationInputs>
          <TextInput source="title_en" label="Title eng" fullWidth />
          <TextInput source="buttonText_en" label="ButtonText eng" fullWidth />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
