import * as React from "react";
import { Filter, TextInput } from 'react-admin';
import { List, Datagrid, TextField, BooleanField, DateField } from 'react-admin';
import { PointOfInterestCategoryEdit } from "./pointOfInterestCategory.edit.component";

const PointOfInterestCategoryFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const PointOfInterestCategoryList = () => {
    return (
        <List
            filters={<PointOfInterestCategoryFilter />}
            exporter={false}
            actions={false}
        >
            <Datagrid rowClick="edit" expand={<PointOfInterestCategoryEdit/>} bulkActionButtons={false}>
                <BooleanField source="isListed" label="Is Listed?" />
                <TextField source="name" />
                <TextField source="pageTitle" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}