import { DateInput, DeleteButton, Edit, NumberInput, required, SaveButton, SimpleForm, TextInput, Toolbar, useRecordContext } from "react-admin";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles({
    inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const EditEntriesButton = () => {
    const record = useRecordContext();

    return (
        <Button
            component={Link}
            to={`/startpages/rows/${record.id}/entries`}
            startIcon={<EditIcon/>}
        >
            Edit Row Entries
        </Button>
    )
};

const EditToolbar = () => (
    <Toolbar>
        <SaveButton />
        <DeleteButton redirect="/startpages/1/rows" />
    </Toolbar>
);

export const StartPageRowEdit = () => {
    const classes = useStyles();

    const record = useRecordContext();
    const { rowId } = useParams();
    const id = record?.id ?? rowId;

    return (
        <Edit resource="startpages/rows" id={id} redirect="/startpages/1/rows">
            <SimpleForm toolbar={<EditToolbar/>}>
                <TextInput source="title" validate={[required()]} />
                <TextInput source="title_en" />
                <NumberInput source="priority"/>

                <EditEntriesButton/>
            </SimpleForm>
        </Edit>
    );
};