import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  DateTimeInput,
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { MapConnection } from "./enums/map-connection.enum";
import { Recurrence } from "./enums/recurrence.enum";
import { Severity } from "./enums/severity.enum";
import { validateInformation } from "./services/validate-information.service";
import { startCase } from "lodash";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const defaultEndDate = () => {
  const now = Date.now();
  // NOTE: Add one day to now for default end date
  return new Date(now + 1000 * 60 * 60 * 24);
};

export const InformationCreate = () => {
  const [mapConnectionValue, setMapConnectionValue] = useState(
    MapConnection.None
  );

  const mapConnectionOptions = Object.keys(MapConnection).map((key) => ({
    id: MapConnection[key],
    name: startCase(key),
  }));

  const statusOptions = Object.keys(PublishingStatus).map((key) => ({
    id: PublishingStatus[key],
    name: startCase(key),
  }));

  const severityOptions = Object.keys(Severity).map((key) => ({
    id: Severity[key],
    name: startCase(key),
  }));

  const recurrenceOptions = Object.keys(Recurrence).map((key) => ({
    id: Recurrence[key],
    name: startCase(key),
  }));

  return (
    <Create>
      <SimpleForm
        validate={validateInformation}
      >
        <SelectInput
          source="status"
          defaultValue={PublishingStatus.draft}
          validate={[required()]}
          choices={statusOptions}
        />
        <SelectInput
          source="severity"
          validate={[required()]}
          choices={severityOptions}
        />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
          validate={[required()]}
        />
        <DateTimeInput
          source="startAt"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <DateTimeInput
          source="endAt"
          defaultValue={defaultEndDate()}
          validate={[required()]}
        />

        <ReferenceArrayInput source="tag_ids" reference="informationtags">
          <SelectArrayInput optionText="name" label="Tags" defaultValue={[]} />
        </ReferenceArrayInput>

        <TextInput source="buttonText" />
        <TextInput source="buttonUrl" />
        <SelectInput
          source="mapConnection"
          choices={mapConnectionOptions}
          onChange={(e) =>
            setMapConnectionValue(e.target.value as MapConnection)
          }
          validate={[required()]}
        ></SelectInput>
        {mapConnectionValue === MapConnection.PointOfInterest && (
          <ReferenceInput
            source="pointOfInterestId"
            reference="pois"
          >
            <AutocompleteInput
              optionText="name"
              label="Point of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {mapConnectionValue === MapConnection.AreaOfInterest && (
          <ReferenceInput
            source="areaOfInterestId"
            reference="aois"
          >
            <AutocompleteInput
              optionText="name"
              label="Area of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {(mapConnectionValue === MapConnection.PointOfInterest ||
          mapConnectionValue === MapConnection.AreaOfInterest) && (
          <NumberInput source="radius" label="radius (m)" />
        )}
        <SelectInput
          label="Recurrence"
          source="recurrence"
          defaultValue={Recurrence.None}
          validate={[required()]}
          choices={recurrenceOptions}
        />

        <TranslationInputs>
          <TextInput source="title_en" label="Title eng" fullWidth />
          <TextInput source="buttonText_en" label="Button Text eng" fullWidth />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Create>
  );
};
