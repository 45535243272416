import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { BulkDeleteButton, BulkDeleteWithConfirmButton, Filter, FunctionField, ReferenceArrayField, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { PoiEdit } from "./poi.edit.component";
import { DisplayStatus } from "../../shared/enums/display-status.enum";
import { startCase, toLower } from 'lodash';

const PoiFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
        <ReferenceInput source="areas" target="id" reference="areas" alwaysOn>
            <SelectInput variant="filled" optionText="name" label="Area" emptyText="All areas" />
        </ReferenceInput>
        <ReferenceInput source="pointOfInterestCategoryId" target="id" reference="poicategories" alwaysOn>
            <SelectInput variant="filled" optionText="name" label="Category" emptyText="All categories" />
        </ReferenceInput>
    </Filter>
);

export const PoiList = () => {
    const getDisplay = (record) => {
        if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
            return `${startCase(toLower(record.display))} (${record.timePeriod.name})`
        }
        return startCase(toLower(record.display));
    };

    return (
        <List
            filters={<PoiFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={<PoiEdit/>}>
                <TextField source="name" />
                <TextField source="pointOfInterestCategory.name" label="Category" sortable={false} />
                <ReferenceArrayField
                    source="area_ids"
                    reference="areas"
                    sortable={false}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField clickable={false} source="name" color={'secondary'} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <FunctionField
                    label="Display"
                    render={(record) => getDisplay(record)}
                />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}