export enum HeaderType {
    Image = 'image',
    Slideshow = 'slideshow',
    Video = 'video',
};

export enum EntryType {
    Path = 'path',
    PathCategory = 'pathCategory',
    Poi = 'poi',
    PoiCategory = 'poiCategory',
};