import * as React from 'react';
import { MenuItemLink } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export interface SubMenuLink {
    text: string
    link: string
    icon: React.ReactElement
}

export interface SubMenuProps {
    text: string
    icon: React.ReactElement
    links: SubMenuLink[]
    sidebarIsOpen: boolean
    dense: boolean | undefined
}

const useStyles = makeStyles(
    (theme: any) => ({
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
    { name: 'RaMenu' }
);

export const SubMenu = (props: SubMenuProps) => {
    const [subMenuIsOpen, setSubMenuIsOpen] = React.useState(true);
    const classes = useStyles(props);

    const rootMenuText = (text = 'Unknown', subMenuIsOpen: boolean) => {
        return (
            <>{text} {subMenuIsOpen ? <ExpandLess /> : <ExpandMore />}</>
        );
    }

    const handleRootClick = (event: React.SyntheticEvent) => {
        setSubMenuIsOpen(!subMenuIsOpen);
        event.preventDefault();
        return false;
    }

    return (
    <>
        {props.sidebarIsOpen && <MenuItemLink
            key={props.text}
            leftIcon={props.icon ?? DefaultIcon}
            primaryText={rootMenuText(props.text, subMenuIsOpen)}
            onClick={handleRootClick}
            to="/test"
            sidebarIsOpen={props.sidebarIsOpen}
            dense={props.dense}
        />}
        <Collapse in={!props.sidebarIsOpen || subMenuIsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                    {props.links.map(link => <MenuItemLink
                        key={link.text}
                        leftIcon={link.icon ?? DefaultIcon}
                        className={`${props.sidebarIsOpen && classes.nested}`}
                        to={link.link}
                        primaryText={link.text}
                        sidebarIsOpen={props.sidebarIsOpen}
                        dense={props.dense}
                    />)}
            </List>
        </Collapse>
    </>);
}

export default SubMenu;