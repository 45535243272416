import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { HeaderType } from "../../shared/enums/start-page.enum";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const EditRowsButton = () => {
  const record = useRecordContext();

  return (
    <Button
      component={Link}
      to={`/startpages/${record.id}/rows`}
      startIcon={<EditIcon/>}
    >
      Edit Rows
    </Button>
  );
};

const headerTypeOptions = Object.keys(HeaderType).map(key => ({
  id: HeaderType[key],
  name: key,
}));

export const StartPageEdit = () => {
  const classes = useStyles();

  return (
    <Edit redirect="/startpages/1">
      <SimpleForm
        toolbar={<CustomToolbar noDelete noRedirect />}
      >
        <TextInput source="title" validate={[required()]} formClassName={classes.inlineBlock}/>
        <TextInput source="title_en" formClassName={classes.inlineBlock}/>

        <TextInput source="body" validate={[required()]} fullWidth/>
        <TextInput source="body_en" fullWidth/>

        <SelectInput source="headerType" choices={headerTypeOptions} validate={[required()]}/>

        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />

        <EditRowsButton/>
      </SimpleForm>
    </Edit>
  );
};
