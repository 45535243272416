import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";

export const TagCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" validate={[required()]} fullWidth/>
                <TranslationInputs>
                    <TextInput source="name_en" label="Name eng" fullWidth />
                </TranslationInputs>
            </SimpleForm>
        </Create>
    );
}