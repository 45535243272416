import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

export const Dashboard = ({ permissions }) => {
    return (
        <Card>
            <Title title="Dashboard" />
            <CardContent>
                <h1>Dashboard</h1>

                {permissions === 'groomer'
                    ? <p>Welcome groomer!</p>
                    : null
                }
                {permissions === 'admin'
                    ? <p>Welcome admin!</p>
                    : null
                }
            </CardContent>
        </Card>
    )
}

export default Dashboard;