import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, DateInput, required, ReferenceArrayInput, SelectArrayInput, ReferenceInput, SelectInput, ImageField, ImageInput, minValue, maxValue, useRecordContext, useGetList, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { TimePeriod } from '../../shared/interfaces/timePeriod.interface';
import GetTimePeriodRange from '../../shared/services/timePeriod.services';
import { validateTimePeriod } from '../../shared/services/validateTimePeriod.service';
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const PoiEdit = () => {
    const classes = useStyles();
    const timePeriods = useGetList<TimePeriod>(
        "timeperiods",
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "ASC" },
        },
    );
    const record = useRecordContext();
    const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
    const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
    const { id } = useParams();
    const poi = useGetOne("pois", { id: id ?? record?.id });
    const selectedTimePeriod = GetTimePeriodRange(
      selectedTimePeriodId,
      timePeriods.data as any
    );
    const displayOptions = Object.keys(DisplayStatus).map(key => ({
        id: DisplayStatus[key],
        name: key,
    }));

    useEffect(() => {
      if (!poi.isLoading) {
        setSelectedTimePeriodId(poi?.data?.timePeriodId);
        setDisplayValue(poi?.data?.display);
      }
    }, [poi.isLoading]);

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source="name" validate={[required()]} formClassName={classes.inlineBlock}/>
                {/*<TextInput source="icon" />*/}
                <NumberInput source="latitude" validate={[required(), minValue(-90), maxValue(90)]} min={-90} max={90}/>
                <NumberInput source="longitude" validate={[required(), minValue(-180), maxValue(180)]} min={-180} max={180}/>
                <NumberInput source="elevation" validate={[required()]}/>
                <TextInput source="url" />
                <TextInput source="description" rows={8} multiline={true} fullWidth={true} />
                <ReferenceInput source="pointOfInterestCategoryId" reference="poicategories" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" label="Category" validate={[required()]} />
                </ReferenceInput>
                <ReferenceArrayInput source="area_ids" reference="areas">
                    <SelectArrayInput optionText="name" label="Areas" />
                </ReferenceArrayInput>
                <SelectInput
                    source="display"
                    choices={displayOptions}
                    onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
                    validate={[required()]}
                ></SelectInput>
                {displayValue === DisplayStatus.Scheduled && (
                    <ReferenceInput
                        source="timePeriodId"
                        reference="timeperiods"
                        onChange={(e) => setSelectedTimePeriodId(e.target.value)}
                    >
                        <SelectInput
                            optionText="name"
                            label="Time period"
                            helperText={selectedTimePeriod}
                            validate={[validateTimePeriod, required()]}
                        ></SelectInput>
                    </ReferenceInput>
                )}
                <ImageInput source="medias" accept="image/*" multiple={true}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <TranslationInputs>
                    <TextInput source="name_en" label="Name eng" formClassName={classes.inlineBlock}/>
                    <TextInput source="description_en" label="Description eng" rows={8} multiline={true} fullWidth={true} />
                </TranslationInputs>
                <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
                <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}