import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import { useParams } from 'react-router-dom';
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { validateTimePeriod } from '../../shared/services/validateTimePeriod.service';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const AoiEdit = () => {
  const classes = useStyles();
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    },
  );
  const record = useRecordContext();
  const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
  const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
  const { id } = useParams();
  const aoi = useGetOne("aois", { id: id ?? record?.id });
  const selectedTimePeriod = GetTimePeriodRange(
    selectedTimePeriodId,
    timePeriods.data as any
  );
  const displayOptions = Object.keys(DisplayStatus).map((key) => ({
    id: DisplayStatus[key],
    name: key,
  }));

  useEffect(() => {
    if (!aoi.isLoading) {
      setSelectedTimePeriodId(aoi?.data?.timePeriodId);
      setDisplayValue(aoi?.data?.display);
    }
  }, [aoi.isLoading]);

  if (timePeriods.isLoading || aoi.isLoading) {
    return <Loading></Loading>;
  }
  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar />}
      >
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ReferenceInput
          source="areaOfInterestCategoryId"
          reference="aoicategories"
        >
          <SelectInput optionText="name" label="Category" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          source="area_ids"
          reference="areas">
          <SelectArrayInput optionText="name" label="Areas"/>
        </ReferenceArrayInput>
        <TextInput
          source="geoJsonFilename"
          label="Current GeoJson file"
          disabled
        />
        <FileInput source="geojson" label="New Geo-Json file" accept=".geojson">
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="display"
          choices={displayOptions}
          onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
          validate={[required()]}
        ></SelectInput>
        {displayValue === DisplayStatus.Scheduled && (
          <ReferenceInput
            source="timePeriodId"
            reference="timeperiods"
            onChange={(e) => setSelectedTimePeriodId(e.target.value)}
          >
            <SelectInput
              optionText="name"
              label="Time period"
              helperText={selectedTimePeriod}
              validate={[validateTimePeriod, required()]}
            ></SelectInput>
          </ReferenceInput>
        )}
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TranslationInputs>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
        <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
      </SimpleForm>
    </Edit>
  );
};
