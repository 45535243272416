import { Datagrid, List, Loading, TextField, TopToolbar, useGetList } from "react-admin";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { StartPageRowEdit } from "./startPageRow.edit.component";

const useStyles = makeStyles({
    emptyRow: { display: "flex", marginTop: "32px", justifyContent: "center"},
});

const CustomCreateButton = (props: any) => {
    return (
        <Button
            {...props}
            component={Link}
            to={`/startpages/1/rows/create`}
            startIcon={<AddIcon/>}
        >
            Create
        </Button>
    )
};

const CustomActions = () => {
    return (
        <TopToolbar>
            <CustomCreateButton/>
        </TopToolbar>
    )
}

export const StartPageRowList = () => {
    const { pageId } = useParams();
    const classes = useStyles();

    const list = useGetList("startpages/rows", {
        filter: { pageId },
    });

    if (list.isLoading) {
        return <Loading></Loading>;
    }

    return (
        <>
            <List resource="startpages/rows" filter={{ pageId }} actions={<CustomActions/>}>
                <Datagrid rowClick="edit" expand={<StartPageRowEdit/>}>
                    <TextField source="title" />
                </Datagrid>
            </List>
            { list?.data?.length === 0 && (
                <div className={classes.emptyRow}>
                    <CustomCreateButton variant="contained" size="small"/>
                </div>
            )}
        </>
    );
};