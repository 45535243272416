import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState } from "react";
import {
  Create,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
} from "react-admin";
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { DisplayStatus } from '../../shared/enums/display-status.enum';
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";
import { validateTimePeriod } from '../../shared/services/validateTimePeriod.service';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const AoiCreate = () => {
  const classes = useStyles();
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    },
  );
  const [displayValue, setDisplayValue] = useState(DisplayStatus.Display);
  const [selectedTimePeriodId, setSelectedTimePeriodId] = useState<number>(0);
  const selectedTimePeriod = GetTimePeriodRange(
    selectedTimePeriodId,
    timePeriods.data || {}
  );
  const displayOptions = Object.keys(DisplayStatus).map((key) => ({
    id: DisplayStatus[key],
    name: key,
  }));

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <ReferenceInput
          source="areaOfInterestCategoryId"
          reference="aoicategories"
        >
          <SelectInput optionText="name" label="Category" validate={[required()]} />
        </ReferenceInput>
        <ReferenceArrayInput
          source="area_ids"
          reference="areas">
          <SelectArrayInput optionText="name" label="Areas" validate={[required()]} defaultValue={[]} />
        </ReferenceArrayInput>
        <FileInput
          source="geojson"
          label="Geo-Json file"
          accept=".geojson"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <SelectInput
          source="display"
          choices={displayOptions}
          defaultValue={DisplayStatus.Display}
          onChange={(e) => setDisplayValue(e.target.value as DisplayStatus)}
          validate={[required()]}
        ></SelectInput>
        {displayValue === DisplayStatus.Scheduled && (
          <ReferenceInput
            source="timePeriodId"
            reference="timeperiods"
            onChange={(e) => setSelectedTimePeriodId(e.target.value)}
          >
            <SelectInput
              optionText="name"
              label="Time period"
              helperText={selectedTimePeriod}
              validate={[validateTimePeriod, required()]}
            ></SelectInput>
          </ReferenceInput>
        )}
        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <TranslationInputs>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
        <TextInput source="socialMedia.instagramUrl" label="Instagram url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.facebookUrl" label="Facebook url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.linkedinUrl" label="Linkedin url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.twitterUrl" label="Twitter url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.vimeoUrl" label="Vimeo url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.youtubeUrl" label="Youtube url" formClassName={classes.inlineBlock}/>
        <TextInput source="socialMedia.tripadvisorUrl" label="Tripadvisor url" formClassName={classes.inlineBlock}/>
      </SimpleForm>
    </Create>
  );
};
