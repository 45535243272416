import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useState, useEffect } from "react";
import {
  DateInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  useGetList,
  useGetOne,
} from "react-admin";
import { useParams } from "react-router-dom";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import { TimePeriod } from "../../shared/interfaces/timePeriod.interface";
import GetTimePeriodRange from "../../shared/services/timePeriod.services";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SettingsEdit = () => {
  const timePeriods = useGetList<TimePeriod>(
    "timeperiods",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    },
  );
  const [selectedSummerId, setSelectedSummerId] = useState<number>(0);
  const [selectedWinterId, setSelectedWinterId] = useState<number>(0);
  const selectedSummerPeriod = GetTimePeriodRange(
    selectedSummerId,
    timePeriods.data || {}
  );
  const { id } = useParams();
  const settings = useGetOne("settings", { id });
  const selectedWinterPeriod = GetTimePeriodRange(
    selectedWinterId,
    timePeriods.data || {}
  );
  const classes = useStyles();

  useEffect(() => {
    if (!settings.isLoading) {
      setSelectedSummerId(settings?.data?.summerSeasonId);
      setSelectedWinterId(settings?.data?.winterSeasonId);
    }
  }, [settings.isLoading]);

  return (
    <Edit redirect="/settings/1">
      <SimpleForm
        toolbar={<CustomToolbar noDelete noRedirect />}
      >
        <ReferenceInput
          source="termsId"
          reference="terms"
          filter={{ status: "published" }}
        >
          <SelectInput optionText="name" label="Terms and Conditions" validate={[required()]}/>
        </ReferenceInput>

        <ReferenceInput
          source="summerSeasonId"
          reference="timeperiods"
          onChange={(e) => setSelectedSummerId(e.target.value)}
        >
          <SelectInput
            optionText="name"
            label="Summer Season"
            helperText={selectedSummerPeriod} 
            validate={[required()]}
          />
        </ReferenceInput>
        <ReferenceInput
          source="winterSeasonId"
          reference="timeperiods"
          onChange={(e) => setSelectedWinterId(e.target.value)}
        >
          <SelectInput 
            optionText="name"
            label="Winter Season"
            helperText={selectedWinterPeriod} 
            validate={[required()]}
          />
        </ReferenceInput>

        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
